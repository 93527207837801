export default {
	// resourcesPath: 'http://192.168.17.59:8092/',
	// vvapi: 'https://apis.test.vv-che.com',
	resourcesPath: 'https://notice.vv-che.com/api/',
	vvapi: 'https://apis.vv-che.com',

	sendVerifyCode: '/sms/common/sms',	// 发送短信验证码
	validCode: '/sms/common/sms/check',	// 短信验证
	login: '/member/findByMobile',	// 登录

	pagePublicity: 'publicity/pagePublicity',	// 查询文件列表
	detailPublicity: 'publicity/detailPublicity',	// 公示详情
	confirmStatus: 'publicityRoster/updateConfirmStatus',	// 确认查看
}