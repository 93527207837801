<template>
  <div class="file-list">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" style="min-height:100vh; 
background: #F3F5F9;" success-text="刷新成功" >
    <van-nav-bar title="文件列表" />

    <div class="container">
      <van-search
        v-model.trim="query.name"
        show-action
        placeholder="搜索文件名称"
        @search="onSearch"
        @clear="reset"
      >
        <template #action>
          <div class="cb"><van-checkbox v-model="checked" shape="square" checked-color="#3476FF" @change="handleUnread" label-position="left">未阅</van-checkbox></div>
        </template>
      </van-search>
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="noMoreTxt"
          @load="onLoad"
          v-if="list.length"
        >
          <div class="box" v-for="item in list" :key="item.index" @click="goDetails(item.id,item.readStatus)" :class="item.readStatus!=='UNREAD'? 'readed':''">
            <div class="top">
              <div class="left">
                <div class="name">{{item.name}}</div>
                <img v-if="!item.confirm" src="@/assets/images/confim.png" alt="">
                <img v-else src="@/assets/images/confimed.png" alt="">
              </div>
              <div class="details">详情>></div>
            </div>
            <div class="bottom"><span class="">{{item.fullName}} </span><span>{{item.createTime.substring(0,16)}}</span><span> 上传</span></div>
          </div>
          <!-- <van-cell is-link center v-for="item in list" :key="item.index" @click="goDetails(item.id,item.readStatus)" :class="item.readStatus!=='UNREAD'? 'readed':''"  >
            <template #title>
              <span class="custom-title es">{{item.name}}</span>
              <van-tag v-if="item.confirmStatus==='NOTCONFIRM'">未确认</van-tag>
              <van-tag v-else class="confim">已确认</van-tag>
            </template>
            <template #label>
              <span class="">{{item.fullName}} </span><span>{{item.createTime.substring(0,16)}}</span><span> 上传</span>
            </template>
          </van-cell> -->
        </van-list>
      <van-empty v-else image="search" description="空空如也" />
    </div>
    </van-pull-refresh>
  </div>
</template>

<script>

export default {
  name: 'fileList',
  data() {
    return {
      checked: false,
      list: [],
      loading: false,
      finished: false,
      scrollTop: 0,
      refreshing: false,
      query: {
        current: 1,
        limit: 10,
        name: '',
        readStatus: ''
      },
      noMoreTxt: ''
    }
  },
  created() {
    this.$EventBus.$on('updateConfim',(e)=> {
      this.updateConfim(e)
    })
    this.$EventBus.$on('listLoad', ()=> {
      this.list = []
      this.onLoad(true)
    })
    this.list = []
    this.onLoad()
  },
  activated() {
    document.documentElement.scrollTop = this.scrollTop
    document.body.scrollTop = this.scrollTop
  },
  methods: {
    reset() {
      console.log('reset')
      this.query.name = ''
      this.onSearch()
    },
    onSearch(val) {

      this.query.current = 1
      this.list = []
      this.finished = false
      this.onLoad()
    },
    updateConfim(id) {
      for(let i = 0; i<this.list.length; i++) {
        if(this.list[i].id === id) {
          this.list[i].confirm = 1
          return
        }
      }
    },
    updateRead(id) {
      for(let i = 0; i<this.list.length; i++) {
        if(this.list[i].id === id) {
          this.list[i].readStatus = 'READ'
          return
        }
      }
    },
    goDetails(id,isRead) {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if(isRead!=='READ'){
        this.updateRead(id)
      }
      this.$router.push('/fileDetails?id='+id)
    },
    onLoad(flag) {
      this.noMoreTxt = ''
      this.$request({
        method: 'POST',
        url: this.$api.pagePublicity,
        data: this.query
      }).then(res=> {
        this.loading = false;
        this.refreshing = false
        let _items = res.items || []

        if(flag) {
          this.list = []
        }

        for(let i = 0; i < _items.length; i++){
          this.list.push(_items[i])
        }
        this.query.current++
        if(res.total === this.list.length) {
          this.finished = true;
          this.noMoreTxt = '没有更多文件了'
        }
      })
    },
    handleUnread(e) {
      this.query.readStatus = e? 'UNREAD': ''
      this.query.current = 1
      this.list = []
      this.onLoad()
    },
    onRefresh() {
      this.finished = false
      this.query.current = 1
      this.query.name = ''
      this.query.readStatus = ''
      this.list = []
      if(!this.checked) {
        this.onLoad()
      }
      this.checked = false
    },
  }
}
</script>

<style lang="scss" scoped>
.file-list {
  min-height: 100vh;
  background: #F3F5F9;
  // background: red;
}


::v-deep .van-nav-bar {
  background: #3476FF;
}

::v-deep .van-nav-bar__title {
  font-size: 17px;
  color: #fff;
}

::v-deep .van-checkbox__label {
  color: #3E4576;
}

::v-deep .van-search {
  padding-left: 15px;
}

::v-deep .van-search__content {
  // width: 270px;
  height: 36px;
  background: #FFFFFF;
  border-radius: 18px;
  border: 1px solid #EAF1FF;
  box-sizing: border-box;
}

::v-deep .van-checkbox__icon {
  font-size: 14px;
}

::v-deep .van-cell {
  align-items: center;
}

.cb {
  width: 60px;
}

.van-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.box {
  width: 345px;
  height: 84px;
  background: #FFFFFF;
  box-shadow: 0px 20px 40px -15px rgba(27, 136, 237, 0.2);
  border-radius: 20px;
  margin-top: 10px;
  padding: 15px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .top {
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      .name {
        color: #3E4576;
        font-size: 16px;
        font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
  // min-width: 0;
  // display: inline-flex;
      }
      img {
        margin-left: 10px;
        width: 46px;
        height: 18px;
      }
    }
    .details {
      color: #3476FF;
      font-size: 12px;
      // opacity:1;
    }
  }
  .bottom {
    font-size: 14px;
    color: #8A8EB1;
  }
}

// ::v-deep .van-cell::after {
//   left: 0;
//   right: 0;
// }

// .custom-title {
//   font-size: 28px;
//   color:#222222;
//   padding-right: 10px;
// }
// .van-tag {
//   background: #3D82E1;
//   color: #fff;
// }
// .confim {
//   background: #DCDCDC;
// }
.readed {
  // background: rgba(0,0,0,0.04);
  opacity: 0.6;
}


.es {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 520px;
  min-width: 0;
  display: inline-flex;
}
</style>