export function cache() {
    if (arguments.length === 1) {
        return JSON.parse(localStorage.getItem(arguments[0]) || '{}');
    } else if (arguments.length === 2) {
        localStorage.setItem(arguments[0], JSON.stringify(arguments[1]));
    }
}

export function setUserInfo(user) {
    cache('userInfo', user)
}

export function getUserInfo() {
    return cache('userInfo')
}

export function removeUserInfo() {
    return localStorage.removeItem('userInfo')
}

export function getToken() {
    return localStorage.getItem('token')
}

export function setToken(token) {
    return localStorage.setItem('token', token)
}

export function removeToken() {
    return localStorage.removeItem('token')
}

export function setMenus(menus) {
    cache('menus', menus)
}

export function getMenus() {
    return cache('menus') || []
}

export function removeMenus() {
    return localStorage.removeItem('menus')
}

export function setButtons(buttons) {
    cache('buttons', buttons)
}

export function getButtons() {
    return cache('buttons') || []
}

export function removeButtons() {
    return localStorage.removeItem('buttons')
}

export function setConstantEnum(enums){
    cache('enums', enums)
}
export function getConstantEnumItem(enumName) {
    return cache('enums') [enumName]
}

export function setValueConfig(valueConfigs){
    cache('value_config',valueConfigs)
}

export function getValueConfig(key){
    let valueConfigs = cache('value_config') || []
    let obj = valueConfigs.filter((v)=>{
       return  v.id == key
    })
    return obj[0]
}

export function setShopEnum(enums) {
    cache('shop_enums', enums)
}

export function getShopEnumItem(enumName) {
    return cache('shop_enums') [enumName]
}
export function setRetailEnum(enums) {
    cache('retail_enum', enums)
}

export function getRetailEnum(enumName) {
    return cache('retail_enum') [enumName]
}
export function setRiceEnum(enums) {
    cache('rice_enum', enums)
}

export function getRiceEnum(enumName) {
    return cache('rice_enum') [enumName]
}


