<template>
  <div class="login">
    <div class="logo">
      <img src="@/assets/images/logo.png" alt="">
    </div>
    
    <div class="login-form">
      <van-form @submit="onSubmit" :show-error="false" ref="form" >
        <van-field
          name="mobile"
          v-model="mobile"
          placeholder="请输入手机号"
          autocomplete="off"
          :rules="[{ required: true, message: '请填写手机号' },{ pattern, message: '手机号格式不正确' }]"
          type="tel"
          class="test"
          center
          :border="false"
        />
        <van-field
          v-model="sms"
          placeholder="请输入验证码"
          :rules="[{ required: true, message: '请填写验证码' }]"
          type="number" 
          class="test"
          center
        >
          <template #button>
            <div class="sendCode" @click="sendCode" >{{sec}}<span v-if="sec>=0">s后重新获取</span></div>
          </template>
        </van-field>
        <van-button block  native-type="submit" class="btn" :class="isActive? 'isActive':''">登录</van-button>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import axios from 'axios'
export default {
  data() {
    return {
      mobile: '',
      sms: '',
      sec: '获取验证码',
      clear: null,
      pattern: /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/,
      isClick: false
    }
  },
  computed: {
    isActive() {
      let res = false

      if(this.mobile.trim() && this.sms.trim() && this.pattern.test(this.mobile)) {
         res = true
      }

      return res
    }
  },
  methods: {
    onSubmit() {
      this.validCode(this.login)
    },
    sendCode() {
      this.$refs.form.validate('mobile').then(res=> {
        if (this.sec !== '获取验证码' && this.sec !== '重新获取') return
        
        if(this.isClick) return
        
        this.isClick = true

        axios({
          url: this.$api.vvapi + this.$api.sendVerifyCode,
          params: { 
            phone: this.mobile,
            type: 2
          }
        }).then(res=> {
            this.sec = 60;
            Toast.success('发送成功')
            this.clear = setInterval(this.countDown, 1000);
          }).catch(err=> {
            Toast(err.data.msg)
          })
      })
    },
    validCode(_callback) {
      var _this = this;
      axios({
        url: this.$api.vvapi + _this.$api.validCode,
        headers: {"accept": "application/json;charset=UTF-8","Content-Type": "application/json"},
        params: {
          phone: _this.mobile,
          vcode: _this.sms
        }
      }).then(res=> {
        _callback();
      }).catch(err=> {
        Toast(err.data.msg)
        Toast({
          message: err.data.msg,
        })
      })
    },
    login() {
      let _this = this
      _this.$request({
        url: _this.$api.login,
        method: 'POST',
        data: {
          mobile: _this.mobile,
          code: _this.sms,
        }
      }).then(res=> {
        if(res.success) {
          localStorage.setItem('token', res.item.token)
          Toast.success('登录成功')
          this.$router.replace(this.$store.state.lastUrl || '/')
          this.$EventBus.$emit('listLoad')
        }
      })
    },
    countDown() {
      var _this = this;
      if (_this.sec > 0) {
        --_this.sec;
      } else {
        clearInterval(_this.clear);
        _this.sec = "重新获取";
        this.isClick = false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.logo {
  padding: 60px 0 40px 0;
  img {
    display: block;
    margin: 0 auto;
    width: 132px;
    height: 60px;
    object-fit: cover;
  }
}
.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  .test {
    width: 315px;
    height: 50px;
    background: #FFFFFF;
    box-shadow: 0px 20px 40px -15px rgba(27, 136, 237, 0.2);
    border-radius: 25px;
    margin-bottom: 20px;
  }
  .test:nth-last-of-type(1) {
    margin-bottom: 50px;
  }

  ::v-deep .van-cell {
    padding:14px 20px;
    overflow:visible;
  }

  ::v-deep .van-field__error-message {
    position: absolute;
    left: 0px;
    top: 35px;
  }

  .sendCode {
    text-align: right;
    font-size: 14px;
    color: #3476FF;
  }
  .btn {
    width: 315px;
    height: 50px;
    background: #B2B5C8;
    border-radius: 25px;
    font-size: 18px;
    font-weight: bold;
    color:#fff;
  }
  .isActive {
    background: #3476FF;
  }
}
</style>