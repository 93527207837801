import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login'
import FileList from '@/views/FileList'
import FileDetails from '@/views/FileDetails'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    name: 'fileList',
    component: FileList,
  },
  {
    path: '/fileDetails',
    name: 'fileDetails',
    component: FileDetails
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
