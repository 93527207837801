<template>
  <div class="file-details">
    <div class="main" v-if="details">
    <van-nav-bar title="公示详情"  left-arrow   @click-left="onBack" >
        <template #right>
        <a :href="'tel:' + details.mobile" ref="telPhone"><img class="" src="@/assets/images/tel.png" alt=""></a>
        <!-- <a :href="'tel:' + details.mobile" ref="telPhone"><van-icon name="phone" /></a> -->
      </template>
    </van-nav-bar>

    <div class="container" v-if="details">
      <div class="header">
        <div class="name">
          <div class="cir" :class="current === 1? 'last':''" @click="pre()"><img src="@/assets/images/left.png" alt=""></div>
          <span  class="es">{{details.name}}</span>
          <div class="cir" :class="current === strip? 'last':''" @click="next()"><img src="@/assets/images/right.png" alt=""></div>
          <!-- <a :href="'tel:' + details.mobile" ref="telPhone"><img class="" src="@/assets/images/tel.png" alt=""></a> -->
        </div>
        <div class="summary"><span>{{details.strip}}</span>条信息<span class="space"></span><span>{{details.fieldAmount}}</span>个字段</div>
        <div class="time">{{details.fullName}} {{details.createTime.substring(0,16)}} 上传</div>
      </div>

      <div class="list">
        <div class="item" v-for="(item,index) in remarkObj" :key="index">
          <div class="left">{{index}}</div>
          <!-- <span class="mh">:</span> -->
          <div class="right">
            <div v-if="item.indexOf('http') === 0" >
              <div class="link"><a :href="item" target="_blank">{{item}}</a></div>
            </div>
            <div v-else>{{item}}</div>
          </div>
        </div>
        <!-- <div class="item">
          <div class="left">字段名1:</div>
          <div class="right">
            <div class="pic" @click="prevImg">查看图片</div>
          </div>
        </div> -->
        <!-- <div class="item">
          <div class="left">字段名1:</div>
          <div class="right">
            <div class="link"><a href="https://vv-che.com" target="_blank">https://vv-che.com</a></div>
          </div>
        </div> -->
      </div>

      <!-- <div class="navbar">
        <div :class="current === 1? 'last':''" @click="pre()">上一条</div>
        <div :class="current === strip? 'last':''" @click="next()">下一条</div>
      </div> -->
    </div>
    </div>


    <div class="confim" v-if="details">
      <van-button block type="info" class="btn" :disabled="this.btn_txt==='已确认'" @click="handleConfim">{{btn_txt}}</van-button>
      <!-- <div class="tip">如对公示信息有疑问，请联系企业负责人</div> -->
    </div>

    <van-empty v-if="error" description="该文件不存在或无权限访问" />
  </div>
</template>

<script>
import { ImagePreview } from 'vant';
import { Dialog } from 'vant';

export default {
  data() {
    return {
      // txt: 'https://vv-che.com',
      // pics: [
      //   'https://jiyun.kuaidi.szdbi.com/api/upload/goodsLogo_20220602023516593_8738.png',
      //   'https://jiyun.kuaidi.szdbi.com/api/upload/goodsLogo_20220602015618157_2815.png',
      //   'https://jiyun.kuaidi.szdbi.com/api/upload/goodsLogo_20220530083127712_4096.png'
      // ],
      strip: 0,
      current: 1,
      id: '',
      details: null,
      remarkObj: {},
      query: {
        publicityId: '',
        sequence: ''
      },
      btn_txt: '确认',
      error: false
    }
  },
  created(opt) {
    // console.log('detail',this.$route.fullPath)
    this.$store.commit('SET_LAST_URL', this.$route.fullPath)
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0

    this.id = this.$route.query.id || ''
    this.query.publicityId = this.id
    this.getDetails()
  },
  methods: {
    getDetails() {
      this.$request({
        method: 'POST',
        url: this.$api.detailPublicity,
        data: this.query
      }).then(res=> {
        this.details = res.item || {}
        this.strip = this.details.strip
        // if(this.details.confirmStatus!=='NOTCONFIRM') {
        //   this.btn_txt = '已确认'
        // }
        this.remarkObj = JSON.parse(this.details.remark[0].remark)
        this.query.sequence = this.details.remark[0].sequence

        if(this.details.remark[0].confirmStatus!=='NOTCONFIRM') {
          this.btn_txt = '已确认'
        }else {
          this.btn_txt = '确认'
        }

      }).catch(err=> {
        this.error = true
      })
    },
    // handleToggle(value) {
    //   if(this.current === this.strip || this.current === 1) return
    //   this.current += value
    //   this.remarkObj = JSON.parse(this.details.remark[this.current-1].remark)
    //   this.query.sequence = this.details.remark[this.current-1].sequence
    //     if(this.details.remark[this.current-1].confirmStatus!=='NOTCONFIRM') {
    //       this.btn_txt = '已确认'
    //     }else {
    //       this.btn_txt = '确认'
    //     }
    // },

    next() {
      if(this.current === this.strip) return
      this.current++
      this.remarkObj = JSON.parse(this.details.remark[this.current-1].remark)
      this.query.sequence = this.details.remark[this.current-1].sequence
        if(this.details.remark[this.current-1].confirmStatus!=='NOTCONFIRM') {
          this.btn_txt = '已确认'
        }else {
          this.btn_txt = '确认'
        }
    },
    pre() {
      if(this.current === 1) return
      this.current--
      this.remarkObj = JSON.parse(this.details.remark[this.current-1].remark)
      this.query.sequence = this.details.remark[this.current-1].sequence
        if(this.details.remark[this.current-1].confirmStatus!=='NOTCONFIRM') {
          this.btn_txt = '已确认'
        }else {
          this.btn_txt = '确认'
        }
    },
    onBack() {
      this.$router.push('/')
    },
    // prevImg() {
    //   console.log('222')
    //   ImagePreview(this.pics);
    // },
    handleConfim() {
      Dialog.confirm({
        message: '确认代表您对该公示信息没有任何疑问，请确认信息无误。',
        confirmButtonColor: '#3D82E1'
      })
        .then(() => {
          this.$request({
            method: 'POST',
            url: this.$api.confirmStatus,
            data: this.query
          }).then(res=> {
            this.btn_txt = '已确认'
            for(let i = 0; i<this.details.remark.length; i++) {
              if(this.details.remark[i].sequence === this.query.sequence) {
                this.details.remark[i].confirmStatus = 'INCONFIRM'

                let isConfimAll = this.details.remark.every(item=> {
                  return item.confirmStatus === 'INCONFIRM'
                })
                if(isConfimAll) {
                  this.$EventBus.$emit('updateConfim', this.id)
                }

                return
              }
            }


          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.file-details {
  // position: relative;
  // min-height: calc(100vh - 200px);
  // padding-bottom: 200px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #F3F5F9;
  .main {
    flex: 1;
  }
}

::v-deep .van-nav-bar {
  background: #3476FF;
  

}
::v-deep .van-nav-bar__title {
  font-size: 17px;
  color: #fff;
}
::v-deep .van-nav-bar__arrow {
  font-size: 18px;
}
::v-deep .van-nav-bar .van-icon {
  color: #fff;
}

::v-deep .van-nav-bar__right {
  a {
    width:24px;
    height: 24px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}


.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .header {
    width: 345px;
    height: 119px;
    background: #FFFFFF;
    box-shadow: 0px 20px 40px -15px rgba(27, 136, 237, 0.2);
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px;
    margin: 10px 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    .name {
      display: flex;
      justify-content: space-between;
      position: relative;
      font-size: 16px;     
      color:  #3E4576;
      font-weight: bold;
      .cir {
        width: 20px;
        height: 20px;
        
        background: #3476FF;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 10px;
          height: 10px;
        }
      }
      .last {
        background: #898EB4;
      }
      // img {
      //   position: absolute;
      //   width: 25px;
      //   height: 24px;
      //   padding-left: 20px;
      // }
    }
    .summary {
      font-size: 14px;
      font-weight: bold;
      color: #3E4576;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        color: #3476FF;
      }
      .space {
        display: inline-block;
        margin: 0 10px;
        width: 2px;
        height: 14px;
        background: #EEEEEE;
        border-radius: 1px;
      }
    }
    .time {
      font-size: 12px;
      color: #8A8EB1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .list {
width: 335px;
background: #FFFFFF;
box-shadow: 0px 20px 40px -15px rgba(27, 136, 237, 0.2);
border-radius: 20px;
display: flex;
flex-direction: column;
align-items: center;
padding-bottom: 15px;
    .item {
      // padding: 22px 0;
      width: 305px;
      border-bottom: 1px solid #F3F4FF;
      box-sizing: border-box;
      padding: 15px 0px 10px 0px;
      display: flex;
      font-size: 14px;
      // line-height: 40px;
      position: relative;
      
      .left {
        width: 80px;
        // padding-left: 20px;
        color:#898EB4;
        // text-align: right;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      // .mh {
      //  color:#999999;
      // }
      .right{
        flex: 1;
        color: #3E4576;
        // padding: 0 40px 0 20px;
        word-wrap:  break-word;
        word-break: break-all;
        .pic {
          color: #3476FF;
        }
        .link {
          height: 100%;
          display: flex;
          align-items: center;
        }
        a {
          color: #3476FF;
        }
      }
    }
  }
}

.confim {
  display: flex;
  justify-content: center;
  .btn {
    font-size: 16px;
    color: #fff;
    margin: 20px 0;
    width: 347px;
    height: 44px;
    background: #3476FF;
    border-radius: 23px;
  }
  .tip {
    padding: 20px 0 30px 0;
    font-size: 22px;
    color:#A1A1A1;
    text-align: center;
  }
}

.es {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 80%;
  display: inline-block;
}
</style>