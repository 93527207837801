import axios from 'axios'
import qs from 'qs'
import api from '@/utils/api'
import {getToken} from '@/utils/localStorage'
import store from '@/store'
import router from '@/router'
import { Toast } from 'vant';

const request = axios.create({
    baseURL: api.resourcesPath,
    headers: {'Content-Type': 'application/x-www-form-urlencoded'}
})

request.interceptors.request.use(config => {
    if (getToken()) { 
        // config.headers['x-access-token'] = getToken().split("_")[0] + "_ms"
        config.headers['x-access-token'] = getToken()
    }
    if (config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
        config.data = qs.stringify(config.data)
        // config.data = qs.stringify(config.data, {arrayFormat: 'repeat'})
    }
    return config
}, error => {
    return Promise.reject(error)
})

// 添加响应拦截器
request.interceptors.response.use(function (response) {
    const {status, data} = response
    if (status >= 200 && status < 300) {
        if (data.success) {
            return response.data
        } else if (data.msg === 'no_user') {
            localStorage.removeItem('token')
            router.replace('/login')

            return Promise.reject(response)
        } else {
            return Promise.reject(response)
        }
    }
    // 对响应数据做点什么
    return response;
}, function (error) {
    switch (error.response.status) {
        case 500:
            Toast('系统繁忙')
            break;
        // case 403:
        //     MessageBox.alert(
        //         '登录状态已过期，或已在其他地方登陆！',
        //         '系统提示',
        //         {
        //             type: 'warning'
        //         }
        //     ).then(() => {
        //         store.dispatch('user/logout').then(() => {
        //             router.push({path: '/login'})
        //         })
        //     })
        //     break;
        // case 504:
        //     Notification.error("连接超时")
        //     break;
        // default:
        //     Notification.error(error.response.status + "")
        //     break;
    }
    // 对响应错误做点什么
    return Promise.reject(error);
});


export default request
